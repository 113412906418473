/* eslint-disable */
//<!--RPD  App v0.9.9
//Copyright(c) 2019 Blue Cover Six LLC / John Molt
//BCS grants unlimited license to The Reno Police Department and the City of Reno-->

import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: [],
    loader_visible: false,
    sidenav_visible: false,
    swal_on: false,
    user: {
      email: "jvonmolt@gmail.com",
      roles: "mst-canlogin",
      token: "likeatokne",
      rpduserId: 0,
      auth: true,
    },
    swal_shown: false,
    brandbar_show: true,
    time_remaining_final_mills: 0,
    time_remaining_warn_mills: 0,
  },
  mutations: {
    setTime_remaining_warn_mills(state, payload) {
      state.time_remaining_warn_mills = payload;
    },
    setTime_remaining_final_mills(state, payload) {
      state.time_remaining_final_mills = payload;
    },
    setBrandbar_show(state, payload) {
      state.brandbar_show = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },

    setSwal_shown(state, payload) {
      state.swal_shown = payload;
    },
    setSwal_on(state, payload) {
      state.swal_on = payload;
    },
    setSidenav_visible(state, payload) {
      state.sidenav_visible = payload;
    },
    setLoader_visible(state, payload) {
      state.loader_visible = payload;
      setTimeout(function () {
        state.loader_visible = false;
      }, 15000);
    },
    setShow_loader(state, payload) {
      state.loader_visible = payload;
      setTimeout(function () {
        state.loader_visible = false;
      }, 15000);
    },
  },
  actions: {
    //anyapi
    anyapi(context, payload) {
      return new Promise((resolve, reject) => {
        Axios.post("api/" + payload.apiname, payload)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data.msg);
            }
          })
          .catch((response) => {
            //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
            try {
              let errormsg = response.data.msg;
              if (errormsg != null || errormsg != "") {
                reject(errormsg);
              }
            } catch (error) {
              reject(response.response.data);
            }
          });
      });
    },

    valtoken(context, payload) {
      return new Promise((resolve, reject) => {
        // console.log("payload:" + payload);
        Axios.post("api/valtoken/", payload)
          .then((response) => {
            if (response.status === 200) {
              // console.log("response.data: " + response.data);
              resolve(response.data);
            } else {
              //  console.log("response err: " + response.status);
              reject(response.data.msg);
            }
          })
          .catch(function (error) {
            let loginURL =
              "https://rpdlogin.org/rpdauth1/?returnURL=https://board.rpdportal.org/";
            let callback = () => {
              window.location.replace(loginURL);
            };
            Vue.prototype.$swal({
              title: "Error",
              text: error.response.data.msg,
              icon: "error",
              confirmButtonText: "OK",
              timer: 3000,
              didClose: callback,
            });
            console.log(error.response.data.msg);
            context.commit("setLoader_visible", false);
          });
      });
    },

    //uploadfile_docs
    uploadfile_test(context, payload) {
      return new Promise((resolve, reject) => {
        // console.log("payload:" + payload);
        Axios.post("api/uploadfile/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              // console.log("response.data: " + response.data);
              resolve(response.data);
            } else {
              //  console.log("response err: " + response.status);
              reject(response.data.msg);
            }
          })
          .catch(function (error) {
            Vue.prototype.$swal({
              title: "Upload Error ",
              text: error.response.data.msg,
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log(error.response.data.msg);
            context.commit("setLoader_visible", false);
          });
      });
    },
  },
});
